$(document).ready(function(){
  jQuery.validator.addMethod("checkMask", function(value, element) {
	return /\+\d{1}\(\d{3}\)\d{3}-\d{4}/g.test(value); 
});

$('.contact-section form').validate();
	$('#form_modal form').validate();

	$.validator.addClassRules({
		'js-phone': {
			checkMask: true
		}
	});

	$('.js-phone').mask("+7(999)999-9999", {autoclear: false});
});

$(function(){ 
	$(".navigation > .nav a").on("click", function(e){
        
        var href = $(this).attr("href"), anchor = $(href);
       
       $("html, body").animate({
          scrollTop: anchor.offset().top -130
       }, 1500);
    });
	
	var buttons = $(".buttons");
	fimenu();
	
    function fimenu(e){
		var scrolltop = $(this).scrollTop();
		
		if(scrolltop > 350){
			buttons.slideDown();
			$(".navigation_container").addClass("navigation_height");
			
		}else{
			buttons.slideUp();
			$(".navigation_container").removeClass("navigation_height");
		}
	}
	
	$(document).on("scroll", fimenu);
	
	buttons.on("click", function(){
		$("html, body").animate({
			scrollTop: 0
		}, 2000);
	});
	
	$(document).on("scroll", fimenu);
	
	var burgerMenu = $(".navbar-header .menu-trigger");
	var content = $(".burger-menu");
	
	burgerMenu.on("click", function(e){
		e.preventDefault();
		burgerMenu.toggleClass("active");
		content.slideToggle();
	});
});